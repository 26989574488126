import { createInertiaApp } from '@inertiajs/svelte';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

createInertiaApp({
    resolve: name => resolvePageComponent(
        `./pages/${name}.svelte`,
        import.meta.glob("./pages/**/*.svelte")
    ),
    setup({ el, App, props }) {
        new App({ target: el, props });

        document.addEventListener('inertia:success', (event) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        });
    },
    progress: {
        color: '#29d',
    },
});
